* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../fonts/Poppins/poppins-bold.ttf);
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(../fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../fonts/Poppins/Poppins-SemiBold.ttf);
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: space-between;
}

.form-group .form-control input {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%;
  padding: 9.5px 15px;
  background-color: transparent;
}

.form-group .form-control input::placeholder {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3e3e3e !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

fieldset {
  border: 0px;
}

.page-border {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 20px;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  /* background:transparent linear-gradient(183deg,#fdc830,#f37335) 0 0 ; */
  background: transparent linear-gradient(183deg, #b62121, #f37335) 0 0;
  border-radius: 4px;
  width: 100%;
  max-width: 380px;
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  height: 44px;
  text-transform: capitalize;
}

.border-btn-main .border-btn {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 6px 14px 5px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
}

/* .border-btn-main .border-btn:hover {
      padding: 6px 14px 5px;
      font-size: 12px;
      line-height: 18px;
      min-width: 100px;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
} */

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: "Poppins-Regular";
}

/* start admin login css */

.admin-login-main-flex {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.admin-login-left-main {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
  /* background: transparent linear-gradient(183deg,#fdc830,#f37335) 0 0; */
  background: transparent linear-gradient(183deg, #b62121, #f37335) 0 0;

  /* border: 1px solid black; */
}

.admin-login-right-main {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
}

.login-left-bg {
  height: 100vh;
  object-fit: cover;
  background-repeat: no-repeat;
  /* background-image: url(../images/png/login-bg.png); */
  background-image: url(../images/png/logo.png);
  background-size: 40% 40%;
  /* width: 100%; */
  background-position: center;
  /* width: 100%; */
}

.admin-login-main {
  max-width: 380px;
  margin: auto;
  width: 100%;
}

.login-btn-main .login-btn {
  margin-top: 28px;
}

.checkbox-main .checkbox-lable {
  color: #6b7280;
  margin-left: 0px;
  margin-right: 0px;
}

.checkbox-lable .MuiCheckbox-root {
  padding: 0px;
  margin-right: 10px;
}

.checkbox-lable .MuiFormControlLabel-label {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
}

.admin-login-inner .admin-wel-text {
  color: #111827;
  font-size: 24px;
  line-height: 25px;
  font-family: "Poppins-Bold";
  margin-bottom: 10px;
}

.admin-login-inner .admin-sign-para {
  margin-bottom: 32px;
  color: #6b7280;
}

.input-box .form-lable {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 6px;
  margin-top: 0px;
  color: #6b7280;
  text-align: start;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  box-shadow: rgba(70, 70, 70, 0.35) 0px 0px 0.8px !important;
  /* box-shadow: 0px 0px 3px #93CED7 !important */
}

.winner-details-modal .form-lable {
  font-size: 14px;
  line-height: 6px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 6px;
  margin-top: 0px;
  color: #6b7280;
  text-align: center;
}


/* .input-box {
  margin-bottom: 28px;
} */

.admin-forgot-para {
  color: #114a65;
}

.input-box .MuiInputBase-root {
  width: 100%;
}

.input-box .MuiInputBase-input {
  padding: 10.5px 15px;
}

.input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.checkbox-main .MuiSvgIcon-root {
  color: #114a65;
  width: 20px;
  height: 20px;
}

/* end admin login css */

/* start admin dashboard page structure css */

.dashboard-left-main {
  flex: 0 0 290px;
  max-width: 290px;
  width: 100%;
  transition: 0.5s all;
}

.dashboard-right-main {
  flex: 1;
}

.dashboard-containt-main {
  background-color: #ffffff;
  min-height: 100vh;
  padding: 84px 24px 24px 24px;
}

.userlist-table-main .MuiPaper-elevation {
  border-radius: 8px;
}

.dashboard-main {
  display: flex;
  width: 100%;
}

/* end admin dashboard page structure css */

/* start admin sidebar css */
.admin-sidebar-logo-main .sidebar-close-btn {
  display: none;
}

.admin-sidebar-icons {
  margin-right: 8px;
}

.admin-sidebar-main {
  /* background-color: #114a65; */
  background: transparent linear-gradient(183deg, #b62121, #f37335) 0 0;
  /* background: transparent linear-gradient(183deg,#fdc830,#f37335) 0 0; */
  height: 100vh;
  left: -500px;
  overflow: auto;
  /* padding: 0px 16px; */
  position: fixed;
  top: 0;
  width: 290px;
  z-index: 999;
  border-right: 1px solid #e5e7eb;
  transition: 0.5s all;
}

.admin-sidebar-main.active {
  left: 0px;
}

.admin-sidebar-deactive {
  flex: 0 0 0px;
  max-width: 0px;
  width: 100%;
  transition: 0.5s all;
}

.admin-sidebar-logo {
  max-width: 130px;
  height: 64px;
  width: 100%;
  object-fit: contain;
  margin: auto;
  display: flex;
  order: 2;
}

.close-icon {
  display: none;
}

.admin-sidebar-list-main {
  padding: 16px 16px;
  /* background-color: #114a65; */
}

.admin-header-drop-main .drop-header-btn {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-list {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-link {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  display: flex;
  padding: 10px 12px 10px;
  margin-bottom: 5px;
  /* margin-bottom: 10px; */
  width: 100%;
  text-decoration: none;
  height: 41px !important;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
  color: #ffffff;
  background-color: rgb(255 255 255 / 23%);
  /* background-color: #1b698e; */
  border-radius: 4px;
}

.admin-sidebar-main::-webkit-scrollbar {
  width: 2px;
}

/* / / Track / / */
.admin-sidebar-main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* / / Handle / / */
.admin-sidebar-main::-webkit-scrollbar-thumb {
  background: #888;
}

/* / / Handle on hover / / */
.admin-sidebar-main::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end admin sidebar css */

/* start admin header css */

.admin-header-main {
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: 290px;
  right: 0px;
  z-index: 9;
  padding: 15px 20px 13px 20px;
  border-bottom: 1px solid #e5e7eb;
  transition: 0.5s all;
}

.admin-header-deactive {
  left: 0px;
}

.admin-header-main.active .admin-sidebar-logo {
  opacity: 0;
  display: none;
}

.admin-header-logo-main .admin-bergur-button {
  padding: 0px;
  min-width: 30px;
}

.admin-header-main.admin-header-deactive .admin-sidebar-logo {
  opacity: 1;
  transition: 0.5s all;
  margin-left: 10px;
  height: 35px;
}

.admin-header-logo-main {
  display: flex;
}

.admin-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-header-icon-box {
  margin-right: 23px;
  cursor: pointer;
}

.admin-header-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.admin-header-profile-icon {
  height: 36px;
  width: 36px;
  object-fit: cover;
  border-radius: 20px;
}

/* end admin header css */

/* start admin dashboard page */

.dashboard-content .admin-page-title {
  color: #111827;
  font-size: 26px;
  line-height: 39px;
  font-family: "Poppins-SemiBold";
  margin-bottom: 20px;
}

.admin-dashboard-inner-box {
  border: 1px solid #d1d5db;
  max-width: 100%;
  width: 100%;
  height: 90px;
  border-radius: 6px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-dashboard-inner-box .admin-dash-text {
  color: #6b7280;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
}

.admin-dashboard-inner-box .admin-dash-price {
  color: #111827;
  font-size: 30px;
  line-height: 46px;
  font-family: "Poppins-Medium";
}

.dash-icons {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.page-table-main .table-container {
  padding-bottom: 7px;
  box-shadow: none;
}

.page-table-main .table {
  margin: 0px 19px;
}

.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: #111827;
  font-family: "Poppins-Medium";
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #d1d5db;
}

.page-table-main .table .table-td:first-child {
  color: #111827;
}

.page-table-main .table-td {
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #d1d5db;
}

.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
  width: 10%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
  width: 10%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
  width: 1%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
  width: 10%;
}

.admin-sub-title-main .admin-sub-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.view-btn-main .view-order-btn {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
}

.view-btn-main .view-order-btn:hover {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
  background-color: #114a65;
  color: #ffffff;
}

.admin-dashboard-table-row {
  border-bottom: 1px solid #d1d5db;
  padding: 20px 19px;
}

.admin-dash-box {
  border-radius: 8px;
  border: 1px solid #d1d5db;
  padding: 0px 0px 20px 0px;
}

.admin-dashboad-row {
  margin-bottom: 24px;
}

.display-row>* {
  padding: 0px 8px;
}

.display-row {
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 15px;

}

/* end admin dashboard page */

.table-lable-main {
  font-family: "Poppins-Regular";
  font-size: 12px;
  line-height: 18px;
}

.paid-lable-dots {
  background-color: #04ab47;
}

.paid-lable {
  color: #04ab47;
}

.table-lable-dots {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}

.table-lable-flex {
  display: flex;
  align-items: center;
}

.failed-lable-dots {
  background-color: #e4004d;
}

.failed-lable {
  color: #e4004d;
}

.pending-lable-dots {
  background-color: #f39c12;
}

.pending-lable {
  color: #f39c12;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* start add user page */

.add-user-containt .add-basic-text {
  color: #111827;
  font-family: "Poppins-SemiBold";
  margin: 0px 0px 4px;
}

.add-user-containt .add-section-text {
  color: #6b7280;
  margin-bottom: 20px;
}

.add-user-input .form-control input {
  /* max-width: 506px; */
  padding: 5.5px 15px;
  /* min-width: 453px; */
}

.add-user-input {
  margin-bottom: 24px;
}

.add-user-input .form-control-textarea {
  width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;
  resize: none;
}

.radio-main .radiogroup .MuiButtonBase-root.Mui-checked {
  color: #114a65;
}

.radio-main .MuiFormControlLabel-label {
  color: #6b7280;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
}

.user-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 60px;
}

/* end add user page */

/*  start dropdown css  */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-box .form-control .dropdown-select {
  border: 1px solid #d1d5db;
}

/* .dropdown-box .form-control svg{
      display: none;
} */
.dropdown-select .MuiSelect-select {
  padding: 6px 15px;
  font-size: 14px;
  line-height: 24px;
  color: #23262f;
  font-family: "Poppins-Medium";
  display: flex;
}

.form-control fieldset {
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

/* / end dropdown css / */

/* start admin add user page */

.discard-btn-main .discard-user-btn,
.discard-btn-main .discard-user-btn:hover {
  min-width: 83px;
  margin-right: 10px;
  height: 36px;
}

.user-save-icon {
  margin-right: 8px;
  object-fit: contain;
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
  min-width: 87px;
  background: transparent linear-gradient(183deg, #b62121, #f37335) 0 0;
  color: #ffffff;
  height: 36px;
}

.submit-privacy {
  background: transparent linear-gradient(183deg, #b62121, #f37335) 0 0;
  color: #ffffff !important;
}

.admin-tabs-main .admin-tab {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  color: #111827;
  text-transform: capitalize;
  padding: 10px 15px;
}

.admin-tabs-main .admin-tab.Mui-selected {
  color: #114a65;
}

.admin-tabs-main .MuiTabs-indicator {
  background-color: #114a65;
  height: 2px;
}

.admin-tabpanel .edit-highlight-text {
  color: #111827;
  font-family: "Poppins-Medium";
  margin-bottom: 4px;
}

.admin-tabpanel .edit-para-text {
  margin-bottom: 24px;
  color: #6b7280;
}

.admin-tabpanel>.MuiBox-root {
  padding: 24px 0px;
}

/* end admin add user page */

/* start admin edit user page */

.edit-profile-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* end admin edit user page */

/* start file upload img css */

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  box-shadow: 0px 3px 6px #00000029;
}

.file-upload-btn-main {
  position: relative;
  max-width: max-content;
}

.upload-profile-img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.upload-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}

/* end file upload img css */

/* start switch css */

.switch-main {
  margin-left: 20px;
}

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: "Poppins-Regular";
}

/* end switch css */

/* start chnage password page css */

.change-pass-main {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 20px;
  max-width: 514px;
  width: 100%;
  overflow-y: auto;

}

.change-pass-input-box .form-group .form-control input {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%;
  padding: 5.5px 15px;
  background-color: transparent;
}

.change-pass-flex {
  margin-bottom: 20px;
}

/* end chnage password page css */

/* start user list page */

.userdata-btn-flex {
  display: flex;
}

.userdata-btn-flex button {
  min-width: 30px;
}

.userlist-data {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.userlist-img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  object-fit: cover;
}

.userlist-table-main .table {
  margin: 0px;
}

.userlist-table-main .table .table-th {
  padding: 10px;
}

.userlist-table-main .table .table-td {
  padding: 10px;

}

.userlist-table-main .table .table-th:nth-child(1),
.userlist-table-main .table .table-td:nth-child(1) {
  width: 3%;
  text-align: center;
}

.userlist-table-main .table .table-th:nth-child(2),
.userlist-table-main .table .table-td:nth-child(2) {
  width: 11%;
  word-break: break-word;
  text-align: center;
}

.userlist-table-main .table .table-th:nth-child(3),
.userlist-table-main .table .table-td:nth-child(3) {
  text-align: center;
  /* width: 0%; */
}

.room-table-list .table .table-th:nth-child(3),
.room-table-list .table .table-td:nth-child(3) {
  /* text-align: center; */
  width: 7% !important;
}

.game-bet-table .table .table-th:nth-child(3),
.game-bet-table .table .table-td:nth-child(3) {
  text-align: center;
  width: 3% !important;
}

.room-table-list .table .table-th:nth-child(4),
.room-table-list .table .table-td:nth-child(4) {
  width: 8% !important;
}

.userlist-table-main .table .table-th:nth-child(4),
.userlist-table-main .table .table-td:nth-child(4) {
  width: 13% !important;
  text-align: center;
}

.userlist-table-main .table .table-th:nth-child(5),
.userlist-table-main .table .table-td:nth-child(5) {
  width: 4%;
  text-align: center;
}

.userlist-table-main .table .table-th:nth-child(6),
.userlist-table-main .table .table-td:nth-child(6) {
  width: 5%;
  text-align: center;
}

.userlist-table-main .table .table-th:nth-child(7),
.userlist-table-main .table .table-td:nth-child(7) {
  width: 5%;
  text-align: center;
}

.userlist-table-main .table .table-th:nth-child(8),
.userlist-table-main .table .table-td:nth-child(8) {
  width: 9%;
  text-align: center;
}

.userlist-table-main .table .table-td:last-child {
  width: 5%;
}

.down-icon,
.plus-icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 9px;
}

.export-btn-main .export-btn,
.export-btn-main .export-btn:hover {
  margin-right: 10px;
  height: 36px;
  min-width: 96px;
}

.adduser-btn-main .adduser-btn,
.adduser-btn-main .adduser-btn:hover {
  min-width: 115px;
  height: 36px;
}

.user-search-box {
  position: relative;
  margin-right: 10px;
}

.user-search-box .form-control input {
  padding: 6px 15px 6px 30px;
}

.user-search-box .form-control input::placeholder {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.search-grey-img {
  position: absolute;
  left: 13px;
  top: 13px;
  height: 13px;
  width: 10px;
  margin-right: 8px;
}

.user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.userlist-btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-list-flex .user-list-page-title {
  margin-bottom: 0px;
}

.table-data-not-found {
  text-align: center;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: gray;
}

.moreVertIcon-disabled {
  opacity: 0.4;
}

.user-list-actions {
  display: flex;
}

.message-icon {
  color: rgb(25, 118, 210);
}

/* end user list page */





/* chat box start  */
.chat-body {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin: 10px
}
.message-button{
  background-color:green !important;
  color: white;max-height:44px !important;
  height:44px !important
}

.chat-input div {
  margin-right: 10px;
  border-radius: 5px;
  background-color: white;

}

.flex-item button {
  border-radius: 5px;
}

.chat-input {
  position: sticky;
  bottom: 0;
  flex: 1;
}

.flex-item {
  display: flex;
  padding: 5px;
  justify-content: space-between;
}

.chat-header {

  background-color: #687074;
}
.chat-button-wrap{
  display: flex;
  align-items: center;
}
.chat-heading {
  display: flex;
  justify-content: flex-start;
  background-color: #ffffff;
  align-items: center;
  margin: 0 0 10px;
}

.chat-heading .back-button button {
  border: none;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 40px !important;
}

.chat-heading h6 {
  font-size: 20px;
  line-height: 21px;
  font-family: 'Poppins-Regular';
}

.chatbox {
  padding: 0px 15px;
  padding-top: 20px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 520px;
  overflow: auto;
}

.bottom-sent-chat .MuiInputBase-colorPrimary {
  height: auto;
  background-color: white !important;
}

.chatbox .chat {
  display: flex;
  gap: 15px;
}

.chatbox img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: solid 2px white;
}

.msg ion-icon {
  /* font-size: 20px;
color: #3a384c; */
  position: absolute;
  top: 42px;
}

.chatbox .chat-left-msg .right-icon {
  left: 0px;
  top: 42px;
  transform: rotate(180deg);

}

.chatbox .chat-right-msg .right-icon {
  right: -5px;
  top: 42px;
  transform: rotate(180deg);

}


.chatbox .chat-left-msg .right-icon {
  left: 0px;
  transform: rotate(180deg);
}

.chatbox .chat-right-msg .right-icon {
  right: -5px;
  transform: rotate(180deg);
}

.chatbox .chat-right-msg {
  flex-direction: row-reverse !important;
}

.chatbox .chat-left-msg {
  flex-direction: row !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.set-search-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}

.set-date-btn input {
  padding: 7.5px 14px !important;
}

.upload-btn-set {
  margin-right: 50px;
}

.upload-btn label {
  background-color: #000000 !important;
  text-transform: capitalize !important;
}

.view-btn-main2 {
  display: flex;
}

.dropdown-box2 {
  width: 300px !important;
  margin-right: 20px;
  /* padding-bottom: 8px !important; */
}

.dropdown-select2 .MuiSelect-select {
  min-height: 37.5px !important;
  align-items: center !important;
  margin-left: 13px !important;

}

.dropdown-select2 fieldset {
  opacity: 0;
}

.box-set-game {
  margin-bottom: 8px !important;
}

.box-set-date p {
  margin-bottom: 0px !important;
}

.table-header {
  align-items: center !important;
}

.table-gap {
  margin-top: 50px !important;
}

.chatbox .chat-right-msg+.chat-right-msg img {
  display: none;
}

.chatbox .chat-right-msg+.chat-right-msg p {
  margin-right: 60px;
}

.chatbox .chat-left-msg+.chat-left-msg img {
  display: none;
}

.chatbox .chat-left-msg+.chat-left-msg p {
  margin-left: 60px;
}

.chatbox .chat-right-msg {
  flex-direction: row-reverse !important;
  margin-top: 10px;
}

.chatbox .chat-left-msg {
  flex-direction: row !important;
  margin-top: 10px;
}

.chat.chat-right-msg+.chat-right-msg,
.chat.chat-left-msg+.chat-left-msg {
  margin-top: 0;
}

.chatbox .msg {
  background: #ffffff;
  max-width: 45%;
  padding: 10px 10px;
  border-radius: 10px;
  position: relative;
  font-size: 13px;
  line-height: 21px;
  font-family: sans-serif;
  word-break: break-word;
  min-height: 40px;
  box-shadow: 0 3px 6px #00000030;
}

/* chat box end  */

/* start add user modal css */

.span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.modal .modal-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}
.modal .add-user-modal-inner-main-wrap{
  padding: 0px 24px 30px 24px;
}
.modal .add-user-modal-inner-main-winner-details{
  padding: 0px 12px 10px 12px;
}
.modal .add-user-modal-inner-main {
  /* padding: 12px 24px 30px 24px; */
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-input-box .form-group .form-control input {
  padding: 5.5px 15px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.room-list-model-avatar {
  align-items: center !important;

  width: 40px;
  height: 40px;
}

.room-list-model-avatar-box {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;

}

/* end add user modal css */

/* start delete user modal css */

.user-circle-img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  /* margin: auto;
  display: flex; */
}
.modal-circle-main{
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
  padding: 12px 12px 15px 0px;
  cursor: pointer;
}
.modal-circle-main-reject{
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
  /* padding: 12px 12px 15px 0px; */
  cursor: pointer;
}
.modal-circle-main-winner-details{
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
  padding:12px 12px 0px 0px;
  cursor: pointer;
}
.modal-delete .delete-modal-inner-main {
  padding: 10px 10px 15px 10px;
  max-width: 420px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.delete-modal-inner-main .delete-modal-title {
  color: #111827;
  font-size: 28px;
  line-height: 42px;
  font-family: "Poppins-Medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: #6b7280;
  margin-bottom: 26px;
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
  color: #6b7280;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background-color: #e4004d;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

/* start header profile dropdown  */

.flex-drop-main .admin-header-drop {
  font-size: 12px;
  font-family: "Poppins-Regular";
  line-height: 18px;
  color: #6b7280;
  text-align: start;
  margin-left: 12px;
  text-transform: capitalize;
}

.flex-drop-main .header-drop-title {
  font-family: "Poppins-Bold";
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.drop-header-menu .MuiPaper-rounded {
  box-shadow: 10px 10px 20px #0000001a;
  border: 1px solid #e5e7eb;
  margin-top: 13px;
  border-radius: 1px;
}

.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.drop-header-menu .drop-header-menuitem {
  font-size: 12px;
  line-height: 14px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  border-bottom: 1px solid #e5e7eb;
  padding: 11px 16px;
}

.drop-header-menu ul {
  padding: 0px;
}

/* end header profile dropdown  */

.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.admin-submenu-listitem-main .admin-sidebar-link {
  display: block;
}

.admin-sidebar-submenulist .admin-sidebar-link {
  margin-left: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-listitem:first-child .admin-sidebar-link {
  margin-top: 20px;
}

/* start pagination css */

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 27px;
  margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: #114a65;
  color: #ffffff;
}

/* end pagination css */

/* start progrss bar css */

.progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: #114a65;
}

.progress-bar-content {
  margin-top: 30px;
}

.progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: #111827;
  font-family: "Poppins-Medium";
}

.progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: "Poppins-Medium";
}

/* end progrss bar css */

/* start filter css */

.filter-main {
  position: relative;
}

.filter-main .MuiDrawer-paper {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
}

.filter-header {
  background-color: #07415d;
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;
  align-items: center;
}

img.filter-close-icon {
  height: 21px;
  width: 21px;
  object-fit: contain;
}

.filter-header .filter-title {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-family: "Poppins-Regular";
}

.filter-inner-main {
  padding: 20px 25px;
  background-color: #fff;
}

.filter-btn-main {
  display: flex;
  justify-content: flex-end;
}

.filter-btn-main .filter-cancel-btn {
  min-width: 90px;
  height: 36px;
  margin-right: 20px;
}

.filter-btn-main .filter-btn,
.filter-btn-main .filter-btn:hover {
  max-width: 90px;
  height: 36px;
}

.filter-footer {
  border-top: 1px solid #d1d5db;
  padding: 15px 25px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.filter-checkbox-main .checkbox-lable {
  margin-right: 20px;
}

/* end filter css */

/* start otp page */

.otp-input-box .form-group .form-control input {
  max-width: 70px;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  font-family: "Poppins-Regular";
}

.otp-input-box .form-group .MuiFormControl-root {
  max-width: 70px;
}

.otp-input-box .form-group .form-control input::placeholder {
  font-size: 20px;
  line-height: 22px;
  font-family: "Poppins-Regular";
}

.otp-flex-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otp-input-box {
  margin-right: 15px;
  margin-bottom: 10px;
}

.otp-flex-main .otp-input-box:last-child {
  margin-right: 0px;
}

/* end otp page */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.error-msg {
  margin: 3px;
  color: red;
}

.forgot-login {
  text-decoration: none;
  color: #6b7280;
}

.sign-out-admin {
  text-decoration: none;
  color: #6b7280;
}

.profile-header {
  text-decoration: none;
  color: #6b7280;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.page-bg-2 {
  background: #eaedef;
}

.nft-box {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.nftpage-main {
  max-width: 100%;
  width: 100%;
  margin-left: 20px;
  margin-top: 7px;
  margin-right: 100px;
}

.admin-login-inner .title-dash {
  margin: 15px 0;
}

.admin-login-inner .admin-sign-para {
  color: #000;
  font-family: "Poppins-Medium";
  font-size: 26px;
  line-height: 37px;
}

.card-Token-main {
  background: #ffffff;
  padding: 20px 17px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #0000002e;
}

.dash-privacy {
  margin-bottom: 15px;
}

.privacybox {
  width: 100%;
}

.dash .dash-page-input .MuiInputBase-colorPrimary fieldset,
.dash .dash-page-input .MuiInputBase-colorPrimary fieldset:hover {
  border-color: #92949452;
}

.dash .dash-page-input .MuiInputBase-formControl input {
  color: #8b8b8d;
  font-family: "Poppins-Regular";
  font-size: 14px;
}

.dash .dash-page-input label {
  color: #8b8b8d;
  font-family: "Poppins-Regular";
  font-size: 16px;
}

.dash .dash-page-input .Mui-focused {
  color: #8b8b8d;
}

.privacy-policy-button-main {
  margin-top: 20px;
  color: white;
  text-align: end;
}

.admin-sidebar-list .MuiList-padding svg path {
  fill: #fff;
}

/* .admin-sidebar-list .MuiList-padding .MuiListItemButton-gutters{
padding: 8px 16px 8px 32px;
} */

.sidebar-inner-nav .cms-drop {
  padding: 10px 12px;
  height: 41px;
}

/* .user-list .admin-sidebar-link{
  margin-bottom: 0;
} */
.sidebar-inner-nav {
  padding: 0 !important;
}

.cms-drop .MuiListItemText-primary,
.sidebar-inner-nav .MuiListItemText-primary {
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
}

.sidebar-inner-nav .MuiListItemButton-gutters {
  height: 41px;
}

.activeStatus {
  background: #00800021;
  padding: 10px;
  border-radius: 6px;
}

.deactiveStatus {
  background: #ff000021;
  padding: 10px;
  border-radius: 6px;
}

.avatar-group div {
  display: flex;
  
  justify-content: center;
}

.avatar-modal {
  margin-right: 203px;
}

.flex-search-btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.main-room-list-modal {
  min-width: 200px !important;
}

.main-room-list-modal thead tr th:last-child {
  width: 10%;
}

.admin-page-title-dashboard {
  font-size: 26px !important;
  font-family: "Poppins-SemiBold" !important;

}

.gen-coin-input {
  display: flex;
  justify-content: space-between;
}

.trans-tab .MuiTab-root {
  font-family: "Poppins-Medium";
}

.token-view-box {
  max-width: 500px;
  width: 100%;
  padding: 25px;
  border: 1px solid #d1d5db;
  border-radius: 10px;
}

.token-view-box-right {
  flex: 1;
}

.token-box-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d5db;
  margin-bottom: 10px;
}

.token-view-box .token-view-lables {
  font-size: 15px;
  line-height: 20px;
  color: #495463;
  font-family: 'Poppins-Medium';
  padding-bottom: 10px;
}

.token-view-box .token-view-data {
  font-size: 12px;
  line-height: 18px;
  color: #495463;
  font-family: 'Poppins-Regular';
}

.token-box-flex:last-child {
  margin-bottom: 0px;
}

.admin-dash-box .page-table-main .MuiPaper-rounded {
  max-height: 440px;
}

.user-detail-card-main {
  display: flex;
  flex-wrap: wrap;
}

.card-head-detail {
  color: #111827;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins-Medium';
  border-bottom: 1px solid #00000042;
  text-align: center;
}

.card-head-table {
  color: #111827;
  font-size: 20px;
  line-height: 39px;
  font-family: 'Poppins-Medium';
  border-bottom: 1px solid #00000042;
  margin-bottom: 20px !important;
}

.user-detail-card {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px #00000052;
  margin-bottom: 15px;
  min-width: calc(100% - 688px);
  margin-right: 15px;
}

.user-detail-card:nth-child(3n-3) {
  margin-right: 0px;
}

.card-body-detail {
  color: #111827;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Poppins-Regular';
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-detail-card p:nth-child(2) {
  margin-top: 15px;
}

.user-detail-table-card {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px #0000009e;
  /* max-width: 1000px; */
  margin-bottom: 15px;
}

.user-detail-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-detail-select {
  height: 38px;
}

.no-data-found {
  font-size: 14px;
  font-family: "Poppins-Medium";
}

.avtar-modal {
  display: flex;
  justify-content: center;
  align-items: center;

}

.table-td-click {
  color: #ff9265;
  text-decoration: underline;
}

.loading-spinner {
  align-items: center !important;
  display: flex;
  justify-content: center;
}

/* changes in bet list start  */
.bet-list-action-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* changes in bet list end */

/* Notification start  */
.notification-main-box {

  width: 70%;
  height: 80vh;
  border: 1px solid #d1d5db;
  overflow-y: auto;
  padding: 10px;

}

.notification-box {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  /* gap: 10px !important; */
  margin-bottom: 10px;
  box-shadow: white 0px 3px 8px;
  padding: 8px;
  background-color: white;
  height: fit-content;
  width: 100%;
  border-radius: 5px;
  overflow-y: auto;
  background: #fff;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.notification-message-box {
  display: flex;
}

.notification-message-title-box {
  margin-left: 10px;

}

.notification-message-title-box .notification-title {
  font-size: 18px;


}

.notification-message-title-box .notification-message {
  font-size: 15px;
  color: gray;
}

.notification-image {
  height: 50px;
  width: 50px;
}

.notification-main-box::-webkit-scrollbar {
  width: 3px !important;

}

/* Notification end  */
.total-coins {
  color: black;
}

.total-coins-heading {
  color: #909297;
}

.loser-color {
  width: 30px;
  height: 31px;
  align-items: center !important;
}

.winner-color-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 5px 0px
}

.pagenotfound-text-heading {
  margin-top: 10px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center;
}

.pagenotfound-text {
  text-align: center !important;
}

.pagenotfound-img {
  margin-top: 10px !important;
  width: 80% !important;
  height: 70% !important;
  display: flex !important;

  margin: auto;
}

.winner-details-modal-admin .form-lable {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: -25px;
  margin-top: 30px;
  color: #6b7280;
  text-align: center;
}
.user-list-header-transaction-wrap{
  display: flex;
  gap: 10px;
}
.transaction-heading{
  text-decoration: underline ;
  text-underline-offset: 5px;
}
.withdrawal-heading{
  text-decoration: underline ;
  text-underline-offset: 5px;
}

.otp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}
.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius:7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #f0f3f8ed;
  font-size: 20px;
}

      .box-login-text p {
        font-size: 12px;
        color: #000;
        line-height: 18px;
        margin: 15px 0px 40px 0px;
        font-family: 'poppins-bold';
      }

      .verify-box {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .resend-otp p {
        margin: 0px !important;
        text-align: center !important;
      }
      
      .resend-otp a {
        color: #000;
        font-family: 'poppins-bold';
      }